<template>
  <div class="add-consultation-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <h4 class="title border-bottom-1px">问题描述</h4>
        <van-field
          class="desc-input"
          v-model.trim="questionContent"
          rows="3"
          autosize
          type="textarea"
          maxlength="100"
          placeholder="请告知问题发生的时间、地址及具体情况，以便我们快速处理，谢谢配合！"
          show-word-limit
        />
      </div>
    </div>
    <div class="confirm-btn" @click="handleSubmit">
      <van-icon name="success" />
    </div>
  </div>
</template>

<script>
import APIS from '@/api/index'
import { saveLoading } from '@/utils/utils'
const { reportConsult } = APIS
export default {
  name: 'addConsultation',
  data () {
    return {
      questionContent: ''
    }
  },
  methods: {
    handleBack () {
      this.$router.back()
    },
    async handleSubmit () {
      if (this.questionContent) {
        saveLoading({
          data: { questionContent: this.questionContent },
          api: reportConsult,
          success: () => {
            this.handleBack()
          }
        })
      } else {
        this.$toast('描述不能为空')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .title{
    padding-bottom: 28px;
    margin-bottom: 28px;
    font-size: 32px;
    color: #333;
    font-weight: 600;
  }
  .desc-input{
    padding: 0px;
  }
  .confirm-btn{
    position: fixed;
    right: 24px;
    bottom: 110px;
    @include circle(114px);
    background:#4FD0B4;
    box-shadow:0px 3px 11px 0px rgba(79,208,180,0.47);
    font-size: 64px;
    color: #fff;
  }
</style>
